import { defineStore } from 'pinia';
import { checkSession, login, logout, localize } from '@/api/auth';
import { updateServices, getUrgent } from '@/api/performer';
import type { Performer } from '@/ontology/performer';
import config from '@/config';
import notifications, { connect } from '@/socket';
import { useAlertsStore } from '@/stores/alerts';
import { useModalsStore } from '@/stores/modals';
import { useSessionStore } from '@/stores/session';
import { useLovenseStoreV2 } from '@/stores/lovense/v2';
import i18n from '@/translations';

import notificationSocket from '@/socket';
import router from '../router';
import { randomNumber } from '@/utils';
import { tokenDate } from '@/api';

let updateServiceTimeout: number | undefined = undefined;
const updateServiceDelay = 1000;

type AuthenticationStatus = 'authenticating' | 'initializing' | 'authenticated' | 'updating' | 'unauthenticated' | 'loggedOut';

interface State {
    status: AuthenticationStatus;
    servicesQueued: any;
    servicesHistory: any;
    servicesStatus: string;
    socket: boolean;
    account: Partial<Performer>;
    languages: any;
    checkInterval?: any;
    authResolvers: (() => void)[];
}

export const useAuthStore = defineStore('performer', {
    state: (): State => ({
        status: 'unauthenticated',
        servicesQueued: [],
        servicesHistory: [],
        servicesStatus: 'ready',
        socket: false,
        account: {
            roles: ['ROLE_UNKNOWN'],
            username: 'anonymous'
        },
        languages: ['nl', 'de', 'en'],
        checkInterval: undefined,
        authResolvers: []
    }),
    actions: {
        run() {
            //Unsubscribe first, fix for double messages...
            notifications.unsubscribeEvent('logout');
            notifications.unsubscribeEvent('login');

            //subscribe to handlers
            notifications.subscribe('logout', async () => {
                this.logout();
            });
            notifications.subscribe('login', async (msg?: { tokenDate: string }) => {
                if (msg) {
                    if (msg.tokenDate !== tokenDate) {
                        this.logout(true);
                    }
                }
            });
        },
        handleNotifier(payload: any) {
            const msg = useAlertsStore();
            if (payload.type === 'new') {
                this.setNotifier(true);
                msg.openMessage({ content: 'auth.alerts.successNewMessage', class: 'success' });
            }
            // Reset services when janus destroyed
            if (payload.sender == 'cam' && payload.value == 'idle') {
                const cam = this.account.performer_services['cam'] ? this.updateService('cam', false) : '';
                const videocall = this.account.performer_services['videocall'] ? this.updateService('videocall', false) : '';
                const voyeur = this.account.performer_services['voyeur'] ? this.updateService('voyeur', false) : '';
            }
        },
        async authenticate() {
            const { error: errorCheck, result } = await checkSession();

            if ((errorCheck && errorCheck.code === 403) || (errorCheck && errorCheck.code === 409)) {
                //checksession failed account is not changed;
                this.setStatus('unauthenticated');
                return;
            }

            this.account = result!;

            await this.initialize();
        },
        async login(email: string, password: string, language: string, force?: boolean, fa2Code?: string) {
            this.setStatus('authenticating');

            const { error, result } = await login(email, password, 'ROLE_PERFORMER', force, fa2Code);
            if (error) {
                //login failed; account is not changed
                this.setStatus('unauthenticated');
                return error;
            }

            this.account = result!;
            this.account.language = language;

            await this.initialize();
        },
        async logout(tab: boolean = false) {
            if (this.status === 'loggedOut') {
                return;
            }
            this.setStatus('loggedOut');

            const msg = useAlertsStore();
            if (!tab) {
                const { error } = await logout();
                if (error) {
                    // Return user status
                    this.setStatus('authenticated');
                    return;
                }

                msg.openMessage({ content: 'auth.alerts.successLoggedOut', class: 'success' });
            } else {
                msg.openMessage({ content: 'auth.alerts.doubleTab', class: 'info' });
            }

            router.push({ name: 'login' });

            this.account.roles![0] = 'ROLE_UNKNOWN';
            this.setStatus('unauthenticated');

            //Unsubscribe first, fix for double messages...
            notifications.unsubscribeEvent('logout');
            notifications.unsubscribeEvent('login');
            notifications.disconnect();
            this.stopSessionCheck();
            const lovense = useLovenseStoreV2();
            lovense.destroy();
        },
        async togglePause() {
            const videoServices = ['cam', 'videocall', 'voyeur', 'peek', 'vipcam', 'phone'];

            if (this.servicesStatus === 'pause') {
                this.servicesHistory = Object.fromEntries(Object.entries(this.account.performer_services).filter(([key, val]) => videoServices.includes(key)));
                for (const service of videoServices) {
                    if (this.account.performer_services[service]) {
                        this.account.performer_services[service] = false;
                    }
                }
                const editedServices = Object.fromEntries(Object.entries(this.account.performer_services).filter(([key, val]) => videoServices.includes(key)));
                await this.putServices(editedServices, true);
            } else {
                for (const [service, value] of Object.entries(this.servicesHistory)) {
                    if (this.servicesHistory[service]) {
                        this.account.performer_services[service] = value;
                    }
                }
                const editedServices = Object.fromEntries(Object.entries(this.account.performer_services).filter(([key, val]) => videoServices.includes(key)));
                await this.putServices(editedServices, true);
                this.servicesHistory = [];
            }

            useAlertsStore().openMessage({ content: this.servicesStatus === 'pause' ? 'sidebar.alerts.pauseactivated' : 'sidebar.alerts.pausedeactivated', class: 'success' });
        },
        async updateService(service: string, status: boolean, delay: boolean) {
            //toys state needs to be saved after you enable toy service
            if (service === 'toy') {
                useLovenseStoreV2().saveRules();
            }

            if (this.servicesQueued[service] !== undefined) {
                delete this.servicesQueued[service];
            } else {
                this.servicesQueued[service] = status;
            }

            this.account.performer_services![service] = status;

            // Check if phone modal triggers services dont show alerts
            if (!useModalsStore().getModal('phone').active && this.servicesStatus !== 'pause') {
                useAlertsStore().openMessage({ content: status ? 'sidebar.alerts.serviceactivated' : 'sidebar.alerts.servicedeactivated', class: 'success' });
            }

            if (updateServiceTimeout) {
                window.clearTimeout(updateServiceTimeout);
            }

            updateServiceTimeout = window.setTimeout(
                async () => {
                    const changes = Object.assign({}, this.servicesQueued);
                    this.servicesQueued = {};
                    if (Object.keys(changes).length === 0) {
                        return;
                    }

                    // Put services changes to api
                    await this.putServices(changes, false);
                },
                delay ? updateServiceDelay : 0
            );
        },
        async putServices(services: any, history?: boolean) {
            const { error, result } = await updateServices(this.account.id!, JSON.stringify(services));
            if (error) {
                if (history) {
                    // Set services back the way the where
                    this.account.performer_services = { ...this.account.performer_services, ...this.servicesHistory };
                } else {
                    // Reset services to init status
                    for (let service in this.account.performer_services) {
                        this.account.performer_services[service] = !this.account.performer_services[service];
                    }
                }
                return;
            }

            // Connect to the notification server and notify services update
            this.setServicesChangesSocket(services);
        },
        async initialize() {
            //TODO: not awaiting anything between initializing and authenticated makes the intiializing status redundant
            this.setStatus('initializing');

            //connect to the notification server
            const { id, socketToken } = this.account!;
            connect(config.SocketUrl, {
                id: id!,
                token: socketToken!,
                type: 'ROLE_PERFORMER'
            });

            //start the checksession dance
            await this.startSessionCheck();
            // start the socket status check
            this.startSocketCheck();

            this.setStatus('authenticated');

            // Checkt urgent actions and execute actions
            this.getUrgentActions();

            // Set localize language & languages
            this.setLocalize({language: this.account.language});

            //unsubscribe to old handlers..
            notifications.unsubscribeEvent('email');
            notifications.unsubscribeEvent('status_change');

            // Start listeners after auth
            notifications.subscribe('email', this.handleNotifier);
            notifications.subscribe('status_change', this.handleNotifier);

            //Open if not activated performer
            if(!this.activatedAccount){
                useModalsStore().toggleModal('notapproved');
            }

            //go with the flow
            const lovense = useLovenseStoreV2();
            if (lovense.status !== 'connected' && lovense.status !== 'connecting') {
                lovense.connect(this.account);
                console.log('Trying to connect');
            }
        },
        async startSessionCheck() {
            const oneMinute = 60 * 1000;
            if (this.checkInterval) {
                clearInterval(this.checkInterval);
            }

            this.checkInterval = setInterval(async () => {
                this.checkSessionInterval();
            }, oneMinute);
        },
        async checkSessionInterval() {
            const { error, result } = await checkSession(false);
            //just in case someone is loggin in while check session is executing
            if (result && this.status == 'authenticated') {
                this.account = result;
            }
            if (error) {
                if (error.code == 403 || error.code == 409 || error.code === 500) {
                    // Reset session store
                    const session = useSessionStore();
                    session.clearRequest();
                    session.setStatus('idle');

                    // logout user
                    this.logout(error.message === 'double tab');
                } else {
                    //TODO: handle this very unexpected error
                }
            }
        },
        async setLocalize(params: any){
            const { error: e10n, result: location } = await localize(params);
            if (e10n) {
                return;
            }

            // Set UX language
            i18n.global.locale.value = this.account.language;

            // Set available languages
            this.languages = location.languages;
        },
        async getUrgentActions() {
            const { error, result } = await getUrgent(this.account.id!);
            if (error) {
                return;
            }

            const actions = result.urgentActions;
            if (actions.length < 1) {
                return;
            }

            actions.forEach((obj: any, index: number) => {
                switch (obj.action) {
                    case 'BioEnrollFace':
                        useModalsStore().toggleModal('face-recog', 'enroll');
                        break;
                    case 'BioCheckFace':
                        useModalsStore().toggleModal('face-recog', 'verify');
                        break;
                    case 'BioEnrollVoice':
                        useModalsStore().toggleModal('voice-recog', 'enroll');
                        break;
                    case 'BioCheckVoice':
                        useModalsStore().toggleModal('voice-recog', 'verify');
                        break;
                    default:
                        console.log(`Do nothing`);
                }
            });
        },
        setServicesChangesSocket(servicesChanges: any) {
            if (!servicesChanges) {
                return;
            }
            ['ROLE_CLIENT', 'ROLE_ADMIN'].forEach(type => {
                notifications.sendEvent({
                    receiverType: type,
                    event: 'service',
                    content: {
                        performerId: this.account.id,
                        services: servicesChanges,
                        countries: this.account.performerCountries
                    }
                });
            });
        },
        setServicesStatus() {
            this.servicesStatus = this.servicesStatus === 'ready' ? 'pause' : 'ready';
        },
        setAccount(account: any) {
            this.account = account;
        },
        stopSessionCheck() {
            clearInterval(this.checkInterval);
        },
        startSocketCheck() {
            window.setInterval(() => {
                this.socket = notificationSocket.isConnected();
            }, 3000);
        },
        setStatus(value: AuthenticationStatus) {
            if (value == this.status) {
                return;
            }

            this.status = value;

            if (value == 'authenticated') {
                this.authResolvers.forEach((resolve: any) => {
                    resolve();
                });
                this.authResolvers = [];
            }
        },
        setAvatar(avatar: Object) {
            this.account.avatar = avatar;
        },
        setNotifier(increments: boolean) {
            if (increments) {
                this.account.totalNotifications += 1;
            } else {
                this.account.totalNotifications -= 1;
            }
        },
        role() {
            if (this.account?.roles?.length) {
                return this.account.roles[0];
            } else {
                return 'ROLE_UNKNOWN';
            }
        },
        isLoggedIn() {
            return this.role() === 'ROLE_PERFORMER';
        },
        getActiveService(service: string) {
            return this.account.performer_services ? this.account.performer_services![service] : false;
        },
        isPerformerServiceActive() {
            const isPerformerServiceActive = ['cam', 'videocall', 'voyeur', 'vipcam'].find(s => this.getActiveService(s));
            return isPerformerServiceActive;
        }
    },
    getters: {
        hasActiveModals(state: any) {
            return state.modals ? state.modals.some((modal: any) => modal.active === true) : false;
        },
        getTheme(state: any) {
            if (state.account?.roles?.length && state.account.roles[0] != 'ROLE_PERFORMER') {
                return 'light';
            }

            return JSON.parse(state.account.settings).theme;
        },
        getNotifications(state: any) {
            return state.account.totalNotifications ? state.account.totalNotifications : 0;
        },
        isSpecialPerformer(state: any) {
            if (!this.isLoggedIn()) {
                return false;
            }
            const performers = ['17627', '17629', '17630', '17631', '17632', '17633', '17634', '17635', '17636', '17637', '17730', '17731', '17732', '17733', '17735', '17736', '17739', '17741', '17740', '17738'];
            return performers.indexOf(state.account.id.toString()) !== -1;
        },
        getForumBlocked(state: any) {
            const forumBlocked = state.account.performer_tags!.find((obj: any) => obj.name === 'ForumBlocked');
            return forumBlocked ? false : true;
        },
        activatedAccount(state: any) {
            return state.account.status === 'APPROVED';
        }
    }
});
